import React from "react";
import Link from "next/link";
import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { routes } from "@src/constants/appRoutes";

export default function Header() {
  return (
    <Flex
      as="header"
      flexShrink={0}
      bg="black"
      px={5}
      py={5}
      justifyContent="space-between"
      alignItems="center"
    >
      <Link href="/">
        <a>
          <Text color="white" fontWeight="bold">
            レツミル
          </Text>
        </a>
      </Link>
      <Box display="flex">
        <Link href={routes.postsSearch()}>
          <a>
            <IconButton
              icon={<SearchIcon />}
              variant="ghost"
              color="white"
              aria-label="Search"
            />
          </a>
        </Link>
      </Box>
    </Flex>
  );
}
