import { FAST_LANE_ROOT_URL } from "@src/constants/website";

export const routes = {
  home: () => "/media",
  login: () => "/login",
  signUp: () => "/sign_up",
  requireEmailVerification: () => "/user/require_email_verification",
  userRegistration: () => "/user/registration",
  userProfile: () => "/user/profile",
  shopShow: (id: string) => `${FAST_LANE_ROOT_URL}/shops/${id}`,
  postsSearch: () => "/media/search",
  postsWithTag: (tag: string) => `/media/tags/${tag}`,
};
